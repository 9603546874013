<template>
  <div class="custom">
    <div class="custom-back" v-bind:class="{cshowBack: hideBack}" @click="back"><van-icon  name="arrow-left" /></div>
    <div class="custom-title">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: 'JdCustom',
  props: {
    title: String,
    hideBack:{
      type:Boolean,
      default: false
    }
  },
  methods:{
    back(){
      history.back();
    }
  }
}
</script>

<style scoped lang="less">
.cshowBack{
  display: none;
}
.custom{
  background-color: #FFFFFF;
  height: 30px;
  position: relative;
  line-height: 32px;
  padding: 6px;
  .custom-title{
    width: 100%;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #262626;
    line-height: 32px;
    text-align: center;
    position: absolute;
    left: 0;
    z-index: 998;
  }
  .custom-back{
    padding-left: 5px;
    width: 20px;
    z-index: 999;
    position: absolute;
    left: 0;
  }
}

</style>
