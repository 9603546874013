<template>
    <div class="card">
        <div v-if="title" class="title" v-text="title"></div>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "CCard",
        props:{
            title:undefined,
        }
    }
</script>

<style lang="less" scoped>
    .card {
        background: #fff;
        padding: 10px 15px;
        margin-bottom: 5px;
        >.title{
            color: #262626;
            font-weight: 600;
            &:before{
                content: " ";
                border-left: 3px solid #016AED;
                margin-right: 10px;
            }
        }

        .van-cell {
            padding-left: 0;
            padding-right: 0;

            &:after {
                left: 0;
                right: 0;
            }
        }

        .van-search {
            padding-left: 0;
            padding-right: 0;
        }
    }
</style>