<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
	// import {getToken} from '@/utils/auth' 
	// 入口组件
	export default {
		name: 'App',
		created() {

		},
	}
</script>


<style lang="less" scoped>
	#app {
		max-width: 720px;
		margin: 0 auto;
		min-height: 100%;
	}
</style>
<style lang="less">
	html {
		background: #f6f6f6;
	}

	html,
	body {
		min-height: 100%;
		font-size: 14px;
		letter-spacing: 0.7px;
	}

</style>
