import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/vant'
import './utils/init'
import JdCustom from './components/jdCustom.vue'
import CustomCard from "./components/CustomCard";
import CustomCheckGroup from "./components/CustomCheckGroup";
Vue.component("JdCustom", JdCustom)
Vue.component(CustomCard.name, CustomCard)
Vue.component(CustomCheckGroup.name, CustomCheckGroup)



Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')