<template>
	<ul class="check-group">
		<li :class="{ 'check-active': item.checked&&!item.disabled,'check-disabled':item.disabled}"
			v-for="item in cloneItems" :key="item.value" v-text="item.label" @click="click(item)"></li>
	</ul>
</template>

<script>
	function deepCopy(o) {
		return JSON.parse(JSON.stringify(o));
	}
	export default {
		name: "CustomCheckGroup",
		model: {
			value: "value",
			event: "change",
		},
		props: {
			value: {
				type: [Array, String, Number],
				default: () => "",
			},
			maxChecked: {
				type: [Array, String, Number],
				default: () => -1,
			},
			items: {
				type: Array,
				default: () => [],
			},
			itemFormatter: {
				type: Function,
				default: (item, index) => item
			}
		},
		data() {
			return {
				cloneItems: [],
			};
		},
		mounted() {
			this.cloneItems = this.itemsConvert(this.items);
		},
		watch: {
			items(v) {
				this.cloneItems = this.itemsConvert(v);
			},
			value(v) {
				if(this.isArrayOfV){
					let vs= new Set(v)
					this.cloneItems.forEach(item=>{
						item.checked=vs.has(item.value)
					})
				}
			}
		},
		computed: {
			isArrayOfV() {
				return this.value.constructor == Array;
			},
		},
		methods: {
			itemsConvert(items) {
				return deepCopy(items).map(this.itemFormatter).map(o => {
					o.checked = false;
					return o;
				})
			},
			click(item) {
				if (item.disabled) {
					return
				}
				this.$emit('click', item)
				if (this.isArrayOfV) {
					let vs
					if (this.maxChecked < 0 || item.checked) {
						item.checked = !item.checked;
						vs = this.cloneItems.filter((o) => o.checked).map((o) => o.value);
					} else {
						vs = this.cloneItems.filter((o) => o.checked).map((o) => o.value);
						if (vs.length < this.maxChecked) {
							item.checked = !item.checked;
							vs = this.cloneItems.filter((o) => o.checked).map((o) => o.value);
						}
					}
					this.$emit("change", vs);
				} else {
					this.cloneItems.forEach((item0) => {
						item0.checked = false;
					});
					item.checked = !item.checked;
					this.$emit("change", item.value);
				}
			},
		},
	};
</script>

<style lang="less" scoped>
	.check-group {
		font-size: 12px;
		list-style: none;

		>li {
			padding: 3px 5px;
			border-radius: 3px;
			border: 1px solid #eee;
			display: inline-block;
			background: #f9f9f9;
			margin: 5px;
      text-align: center;
		}

		.check-active {
			border: 1px solid #016aed;
			color: #016aed;
		}

		.check-disabled {
			cursor: no-drop;
			border: 1px solid #e3e3e3;
			color: #e3e3e3;
		}
	}
</style>>
