import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [{
        path: '/',
        name: 'base',
        component: () =>
            import('../layout/BasicLayout.vue'),
        meta: {
            title: '首页'
        },
        children: [{
                path: '/home',
                name: 'home',
                component: () =>
                    import('../views/home/Index.vue'),
                meta: {
                    title: '商机大厅'
                }
            }, {
                path: '/demand-list', //需求列表
                name: 'demandList',
                component: () =>
                    import('../views/demand/demandList.vue'),
                meta: {
                    title: '发现'
                }
            },
            // ,{
            //     path: '/',
            //     name: 'find',
            //     component: () =>
            //         import ('../views/find/Index.vue'),
            //     meta: {
            //         title: '发现'
            //     }
            // }
            // ,{
            //     path: '/',
            //     name: 'news',
            //     component: () =>
            //         import ('../views/news/Index.vue'),
            //     meta: {
            //         title: '消息'
            //     }
            // },
            {
                path: '/demand',
                name: 'demand',
                component: () =>
                    import('../views/demand/demand.vue'),
                meta: {
                    title: '发布需求'
                }
            },
            {
                path: '/demand/:id',
                name: '需求详情',
                component: () =>
                    import('../views/demand/demandDetails.vue'),
                props: true,
                meta: {
                    title: '需求详情'
                }
            },
           
            {
                path: '/',
                name: 'home',
                component: () =>
                    import('../views/guide/Index.vue'),
                meta: {
                    title: '引导页'
                }
            },
            {
                path: '/maa',
                name: 'home',
                component: () =>
                    import('../views/home/Index.vue'),
                meta: {
                    title: '我的预约'
                }
            },
            {
                path: '/user',
                name: 'user',
                component: () =>
                    import('../views/user/Index.vue'),
                meta: {
                    title: '我的'
                }
            }
        ]
    },
    {
        path: '/0',
        name: 'base',
        component: () =>
            import('../layout/EmptyLayout.vue'),
        meta: {
            title: ''
        },
        children: [{
                path: '/guide',
                name: 'guide',
                component: () =>
                    import('../views/guide/Index.vue'),
                meta: {
                    title: '完善信息'
                }
            },{
            path: '/flow',
            name: 'flow',
            component: () =>
                import('../views/guide/flow.vue'),
            },{
            path: '/zhaopin',
            name: 'zhaopin',
            component: () =>
                import('../views/guide/zhaopin.vue'),
        },{
            path: '/yellow',
            name: 'yellow',
            component: () =>
                import('../views/home/yellow.vue'),
        }, {
                path: '/select',
                name: 'select',
                component: () =>
                    import('../views/guide/select.vue'),
                meta: {
                    title: '请选择你的身份'
                }
            }, {
                path: '/personal',
                name: 'personal',
                component: () =>
                    import('../views/guide/personal.vue'),
                meta: {
                    title: '完善信息'
                }
            }, {
                path: '/key-word',
                name: 'keyWord',
                component: () =>
                    import('../views/keyWord/Index.vue'),
                meta: {
                    title: '定制招工信息'
                }
            }, {
                path: '/perfectInfo',
                name: 'perfectInfo',
                component: () =>
                    import('../views/guide/perfectInfo.vue'),
                meta: {
                    title: '完善信息'
                }
            }, {
                path: '/perfectInfoB',
                name: 'perfectInfoB',
                component: () =>
                    import('../views/guide/perfectInfoB.vue'),
                meta: {
                    title: '完善信息'
                }
            }, {
                path: '/login',
                name: 'login',
                component: () =>
                    import('../views/login/login.vue'),
                meta: {
                    title: '登录',

                    hideNav: true
                }
            }, {
                path: '/auth',
                name: 'auth',
                component: () =>
                    import('../views/login/Auth.vue'),
                meta: {
                    title: '登录认证',

                    hideNav: true
                }
            },
            {
                path: '/guide',
                name: '基础信息',
                component: () =>
                    import('../views/guide/personal.vue'),
                meta: {
                    title: '基础信息'
                }
            }
        ]
    }, {
        path: '/',
        name: 'base',
        component: () =>
            import('../layout/EmptyLayout.vue'),
        meta: {
            title: ''
        },
        children: [
            {
                path: '/admin-demand-details/:id',
                name: 'admin-demand-details',
                component: () =>
                    import('../views/demand/adminDemandDetails.vue'),
                props: true,
                meta: {
                    title: '审核需求'
                }
            },
            {
                path: '/demand-modify/:id',
                name: 'demand-modify',
                component: () =>
                    import('../views/demand/demandModify.vue'),
                props: true,
                meta: {
                    title: '需求编辑'
                }
            },
            {
                path: '/mydemand/:id',
                name: 'mydemand',
                component: () =>
                    import('../views/demand/myDemandDetails'),
                props: true,
                meta: {
                    title: '需求详情'
                }
            }, {
                path: '/my-treasure',
                name: '我的元宝',
                component: () =>
                    import('../views/user/myTreasure.vue'),
                meta: {
                    title: '我的元宝'
                }
            }, {
                path: '/my-integral',
                name: '我的积分',
                component: () =>
                    import('../views/user/myIntegral.vue'),
                meta: {
                    title: '我的积分'
                }
            }, {
                path: '/integral-acquisition',
                name: 'integral-acquisition',
                component: () =>
                    import('../views/user/integralAcquisition.vue'),
                meta: {
                    title: '积分获取说明'
                }
            }, {
                path: '/my-resume',
                name: '我的简历',
                component: () =>
                    import('../views/user/myResume.vue'),
                meta: {
                    title: '我的简历'
                }
            }, {
                path: '/my-authentication',
                name: '认证信息',
                component: () =>
                    import('../views/user/myAuthentication.vue'),
                meta: {
                    title: '认证信息'
                }
            }, {
                path: '/my-user-authentication',
                name: '认证信息',
                component: () =>
                    import('../views/user/myUserAuthentication.vue'),
                meta: {
                    title: '认证信息'
                }
            },
            {
                path: '/admin-demand-list',
                name: '信息审核',
                component: () =>
                    import('../views/demand/adminDemandList.vue'),
                meta: {
                    title: '信息审核'
                }
            },
            {
                path: '/my-demand-list',
                name: '我的需求',
                component: () =>
                    import('../views/demand/myDemandList.vue'),
                meta: {
                    title: '我的需求'
                }
            },
            {
                path: '/my-invite',
                name: '我邀请的用户',
                component: () =>
                    import('../views/user/myInvite.vue'),
                meta: {
                    title: '我邀请的用户'
                }
            },
            {
                path: '/withdrawal-record',
                name: '提现记录',
                component: () =>
                    import('../views/user/withdrawalRecord.vue'),
                meta: {
                    title: '提现记录'
                }
            }, {
                path: '/my-credit',
                name: '我的信用',
                component: () =>
                    import('../views/user/myCredit.vue'),
                meta: {
                    title: '我的信用'
                }
            }, {
                path: '/set-up',
                name: 'setUp',
                component: () =>
                    import('../views/user/setUp.vue'),
                meta: {
                    title: '设置'
                }
            }, {
                path: '/privacy-agreement',
                name: '隐私协议',
                component: () =>
                    import('../views/user/privacyAgreement.vue'),
                meta: {
                    title: '隐私协议'
                }
            }, {
                path: '/service-agreement',
                name: '服务协议',
                component: () =>
                    import('../views/user/serviceAgreement.vue'),
                meta: {
                    title: '服务协议'
                }
            }

        ]
    },

]



const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})




router.beforeEach((to, from, next) => {
    let authUrl = `${window.location.protocol}//${window.location.host}`
    console.log(to.name, authUrl)
    next();
    // if (to.path == '/logout') {
    //     alert('退出登录成功')
    //     setTimeout(() => {
    //         store.commit('setUserInfo0', null)
    //     }, 2500)
    //     setTimeout(() => {
    //         window.location.href =
    //             'https://jaccount.sjtu.edu.cn/oauth2/logout?client_id=6p2nIKUoBrhGu0Hcw7Ph&redirect_uri=' +
    //             authUrl + '/logout-success'
    //     }, 3000)
    // } else if (store.state.token) {
    //     if (to.name == 'login') {
    //         next('/');
    //     } else {
    //         next();
    //     }
    // } else if (
    //     to.name == 'login' ||
    //     to.name == 'auth' ||
    //     to.name == 'logoutSuccess' ||
    //     to.name == 'home' ||
    //     to.name == 'search' ||
    //     to.name == 'msg' ||
    //     to.name == 'msgDetail' ||
    //     to.name == 'venue-entry' ||
    //     to.name == 'exhibition-entry'
    // ) {
    //     next();
    // } else {
    //     let nextUrl = '/login?redirectUrl=' + (to.fullPath ? to.fullPath : "/")
    //     console.info("router298", nextUrl, to)
    //     next(nextUrl);
    // }
})

export default router