import Vue from 'vue'
import Vuex from 'vuex'
import { SET_TOKEN, SET_USER, SET_TABBAR,SET_ROLE } from '../const.js'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: sessionStorage.getItem('token'),
        user: sessionStorage.getItem('user'),
        role: sessionStorage.getItem('role'),
        tabbar: sessionStorage.getItem('tabbar'),
    },
    mutations: {
        [SET_TOKEN](state, token) {
            sessionStorage.setItem('token', token)
            state.token = token
        },
        [SET_USER](state, user) {
            sessionStorage.setItem('user', user)
            state.user = user
        },
        [SET_ROLE](state, role) {
            sessionStorage.setItem('role', role)
            state.role = role
        },
        [SET_TABBAR](state, tabbar) {
            sessionStorage.setItem('tabbar', tabbar)
            state.tabbar = tabbar
        },
    },
    actions: {},
    modules: {}
})